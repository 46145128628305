<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact
      >Historia Clinica - {{ name_historia }}</page-header-compact
    >
    <br />
    <div class="container-xl px-4">
      <div class="card">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <br />
      <wizard
        :steps="steps.length"
        @prev="prev($event)"
        @next="next($event)"
        :current_step="current"
      >
        <template #tabs>
          <wizard-tab
            v-for="(component, i) in steps"
            :key="`tabs_${i}`"
            :title="component.title"
            :step="i + 1"
            :active="i + 1 === current"
            :id="`step${i + 1}`"
            v-on:tab-click="next({ prev: current, current: i + 1 })"
          >
          </wizard-tab>
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                :is="currentComponent.ref"
                :ref="currentComponent.ref"
                v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <div class="row">
            <remision-component
              :idHistoria="idHistoria"
              :usuario="usuario"
            ></remision-component>
            <finalizar-historia
              :id-usuario="usuario.id"
              :id-historia="idHistoria"
              :codigoHistoria="'RNC'"
            ></finalizar-historia>
          </div>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>
import Wizard from "../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
import examenFisicoComponent from "../base/examenFisico/examenFisico";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../services/historiaClinicaService";
import ordenComponent from "../base/orden/ordenComponent";
import formulaMedicaComponent from "../base/formulaMedica/formulaMedicaComponent";
import impresionDXComponent from "../base/impresionDX/impresionDXComponent";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import intervenciones from "@/views/historias/base/intervenciones/intervenciones";
import recomendaciones from "@/views/historias/base/recomendaciones/recomendaciones";
import revisionSistemas from "@/views/historias/base/baseUrgencia/revision";
import FinalizarHistoria from "@/views/historias/base/finalizarHistoria/finalizarHistoria";
import embarazoService from "@/services/embarazoService";
import dayjs from "dayjs";
import RemisionComponent from "../base/baseRemision/index";
import resumenHcComponent from "./resumenHc/resumenHcComponent";
import riesgoPreconcepcional from "@/views/historias/base/baseRiesgoPreconcepcional/index";
import formulario from "../base/baseRespuestaFormularios/index";
import indexExamenes from "../base/baseReporteExamenesAdolescente/indexExamenes";

export default {
  components: {
    FinalizarHistoria,
    PageHeaderCompact,
    motivoConsultaComponent,
    examenFisicoComponent,
    WizardTabPane,
    WizardTab,
    Wizard,
    ordenComponent,
    formulaMedicaComponent,
    impresionDXComponent,
    modalInformacionUsuario,
    cardInformacionUsuario,
    intervenciones,
    recomendaciones,
    revisionSistemas,
    RemisionComponent,
    resumenHcComponent,
    riesgoPreconcepcional,
    formulario,
    indexExamenes,
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      idTipoHistoria: "",
      usuario: {},
      ultimoEmbarazo: {},
      current: 1,
      checkpoint: 1,
      name_historia: "",
    };
  },
  methods: {
    prev(step) {
      this.current = step.current;
      this.activeTab();
    },
    async next(step) {
      if (step.prev <= this.checkpoint && step.current - 1 > step.prev) {
        this.activeTab();
        return;
      }

      const refComponent = this.steps[step.prev - 1].ref;

      if (typeof this.$refs[refComponent].save !== "undefined") {
        await this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if (step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      const response = await historiaClinicaService.show(id);
      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;
      this.name_historia = response.data.tipo_historia.nombre;

      //this.current = response.data.checkpoint;
      //this.checkpoint = response.data.checkpoint;

      this.idTipoHistoria = response.data.id_tipo_historia;

      this.activeTab();

      this.ultimoEmbarazo = (
        await embarazoService.showByLast(this.usuario.id)
      )?.data;

      this.current = response.data.checkpoint;
      this.checkpoint = response.data.checkpoint;
    },
    activeTab() {
      document.querySelectorAll(`a.active`).forEach((a) => {
        a.classList.remove("active");
      });
      document
        .querySelector(`a[href='${this.steps[this.current - 1].stepId}']`)
        .classList.add("active");
    },
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {
      if (this.currentComponent.ref === "index") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          usuario: this.usuario,
        };
      }

      if (this.currentComponent.ref === "formulario") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          codigo: "R_PRECON",
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent.ref === "motivoConsultaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
        };
      }

      if (this.currentComponent.ref === "examenFisicoComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          modo: "PRECOM",
        };
      }

      if (this.currentComponent.ref === "ordenComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent.ref === "formulaMedicaComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
        };
      }
      if (this.currentComponent.ref === "impresionDXComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        };
      }

      if (this.currentComponent.ref === "resumenHcComponent") {
        return {
          idHistoria: this.idHistoria,
          idUsuario: this.usuario.id,
        };
      }

      if (this.currentComponent.ref === "revisionSistemas") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          sexo: this.usuario.sexo,
        };
      }

      if (this.currentComponent.ref === "intervenciones") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent.ref === "recomendaciones") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario: this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      if (this.currentComponent.ref === "riesgoPreconcepcional") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario: this.usuario.id,
          usuario: this.usuario,
          embarazo_actual: this.ultimoEmbarazo,
        };
      }

      if (this.currentComponent.ref === "indexExamenes") {
        return {
          idHistoria: this.idHistoria,
          usuario: this.usuario,
          idUsuario: this.usuario.id,
          idTipoHistoria: this.idTipoHistoria,
        };
      }

      return {};
    },
    currentComponent() {
      return this.steps[this.current - 1];
    },
    steps() {
      let components = [
        {
          ref: "motivoConsultaComponent",
          title: "Motivo de Consulta",
          validate: true,
        },
        {
          ref: "examenFisicoComponent",
          title: "Examen Fisico",
          validate: true,
        },
        {
          ref: "revisionSistemas",
          title: "Revision por Sistemas",
          validate: true,
        },
        { ref: "indexExamenes", title: "Reporte Examenes", validate: false },
        {
          ref: "riesgoPreconcepcional",
          title: "Riesgo Preconcepcional",
          validate: true,
        },
        {
          ref: "formulario",
          title: "Recomendación para Embarazo",
          validate: true,
        },
        { ref: "impresionDXComponent", title: "Impresión DX", validate: true },
        { ref: "ordenComponent", title: "Ordenes", validate: false },
        { ref: "formulaMedicaComponent", title: "Formulas", validate: false },
        { ref: "recomendaciones", title: "Recomendaciones", validate: false },
        { ref: "resumenHcComponent", title: "Resumen", validate: false },
      ];

      /* if(this.edad >= 0 && this.edad <= 5){
        components.push({ ref: "index", title:"AIEPI",validate: false });
        components.push({ ref: "resumenHcComponent", title:"Resumen",validate: false });
      }else if(!isEmpty(this.ultimoEmbarazo)){
        if(this.ultimoEmbarazo.activo === 'S'){
          components.push({ ref: "index", title:"AIEPI",validate: false });
          components.push({ ref: "resumenHcComponent", title:"Resumen",validate: false });
        }
      }else{
        components.push({ ref: "resumenHcComponent", title:"Resumen",validate: false });
      } */

      return components.map((x, i) => {
        return {
          stepId: `#step${i + 1}`,
          ...x,
        };
      });
    },
    edad: function () {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "years");
    },
  },
};
</script>
<style scoped></style>
